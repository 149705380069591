const slider = {};

function initiateSlideshow(element, index, id) {
  const options = {
    effect: 'slide',
    slidesPerView: 1,
    loop: false,
    navigation: {
      nextEl: `.carousel__btn-next-${id}`,
      prevEl: `.carousel__btn-prev-${id}`,
    },
    // pagination: {
    //   el: `.swiper-pagination`,
    // },
    pagination: {
      el: `.swiper-pagination-${id}`,
      clickable: true,
      // Return bullets as numbers
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
    breakpoints: {
      700: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1025: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };

  // initialize the swiper and add it to the array
  slider[index] = new Swiper(element, options);
}

export default function carouselComponent() {
  const slideShows = document.querySelectorAll('.carousel--cards');

  slideShows.forEach((slideshow, index) => {
    const numberOfSlides = slideshow.querySelectorAll('.swiper-slide').length;
    const carousel = slideshow.querySelector('.carousel-wrapper');
    const id = carousel.dataset.id;

    if (numberOfSlides > 3) {
      initiateSlideshow(carousel, index, id);
    }
  });
}

document.addEventListener('DOMContentLoaded', function () {
  carouselComponent();
});

if (window.acf) {
  window.acf.addAction('render_block_preview', carouselComponent);
}
